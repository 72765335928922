import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons';
import { faStarHalfAlt as faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';

export default class Stars extends Component {
  render() {
    
    const stars = [];
    
    for(let i = 0; i<this.props.full; i++) {
      stars.push( <FontAwesomeIcon icon={faStarFull} key={i}/> );
    }
    
    if (this.props.half) {
      stars.push( <FontAwesomeIcon icon={faStarHalf}  key={100}/> );
    }
    
    const empty = this.props.total - (this.props.full + (this.props.half?1:0));
    for(let i = 0; i<empty; i++) {
      stars.push( <FontAwesomeIcon icon={faStarEmpty} key={i+200} /> );
    }
    
    return (
      <span {...this.props}>
        {stars}
      </span>
    );
  }
}

Stars.defaultProps = {
  full:5,
  half:undefined,
  total:5,
}